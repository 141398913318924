/* 验证手机号码 */
export const checkMobile = (rule, value, callback) => {
    // let phoneRex = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;
    let phoneRex = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    if (!value) {
        callback();
    }
    if (!phoneRex.test(value)) {
        callback(new Error('手机号格式不正确！'));
    } else {
        callback();
    }
}
/**验证密码 */
export const checkPassword = (rule, value, callback) => {
    let passwordRex = /^[a-zA-Z0-9]{6,15}$/
    if (!value) {
        callback();
    }
    if (!passwordRex.test(value)) {
        callback(new Error('请输入6~15位的英文字母或数字'));
    } else {
        callback();
    }
}