<template>
    <div class="login">
        <div class="container">
            <div class="container-left">
                <div class="text">欢迎登录!</div>
                <div class="login_form">
                    <div class="tab" v-if="showContent === 'login'">
                        <div :class="['tabs',activeIndex === 1 ? 'active_tabs' : '']" @click="activeIndex = 1">密码登录</div>
                        <div :class="['tabs',activeIndex === 2 ? 'active_tabs' : '']" @click="activeIndex = 2">验证码登录</div>
                    </div>
                    <div v-else class="tab2">重置密码</div>
                    <div class="login_box">
                        <a-form-model
                            ref="loginForm"
                            @submit.native.prevent
                            :rules="rules"
                            :model="formLogin">
                            <div v-if="showContent === 'login'" >
                                <div class="user_login" v-if="activeIndex === 1">
                                    <a-form-model-item prop="account">
                                        <a-input
                                            type="text"
                                            v-model="formLogin.account"
                                            placeholder="请输入用户">
                                        </a-input>
                                    </a-form-model-item>
                                    <a-form-model-item prop="password">
                                        <a-input
                                            type="password"
                                            v-model="formLogin.password"
                                            placeholder="请输入密码">
                                        </a-input>
                                    </a-form-model-item>
                                </div>
                                <div class="phone_login" v-if="activeIndex === 2">
                                    <a-form-model-item prop="mobile" class="phone-box">
                                        <a-input
                                            type="text"
                                            v-model="formLogin.mobile"
                                            placeholder="请输入手机号">
                                        </a-input>
                                    </a-form-model-item>
                                    <SlideVerify ref="slideblock" @success="onSuccess" @again="onAgain" @fail="onFail" @refresh="onRefresh" :slider-text="sliderText" :imgs="imgs" :accuracy="accuracy"></SlideVerify>
                                    <a-form-model-item prop="phoneCode" class="phonecode_box">
                                        <div style="display:flex;">
                                            <a-input type="text" v-model="formLogin.phoneCode" placeholder="请输入验证码"></a-input>
                                            <a-button type="primary" class="code_button" :disabled="codeDisabled" @click.native="getPhoneCode">{{text}}</a-button>
                                        </div>
                                    </a-form-model-item>
                                </div>
                                <div class="loginbox">
                                    <a-form-model-item prop="checked" class="check_box">
                                        <a-checkbox v-model="checked" @change="changeCheck()" v-if="activeIndex === 1">下次自动登录</a-checkbox>
                                        <div class="txt" @click="resetPassword">忘记密码?</div>
                                    </a-form-model-item>
                                    <a-button
                                        @click="submit"
                                        type="primary"
                                        class="button-login"
                                        :disabled='disabled'>
                                        登录</a-button>
                                </div>
                            </div>
                            <div v-if="showContent === 'resetPass'" class="resetPassbox">
                                <a-form-model-item prop="phone">
                                    <a-input
                                        type="text"
                                        v-model="formLogin.phone"
                                        placeholder="请输入手机号">
                                    </a-input>
                                </a-form-model-item>
                                <a-form-model-item prop="newPass">
                                    <a-input
                                        type="text"
                                        v-model="formLogin.newPass"
                                        placeholder="请输入6-15位英文字母或数字">
                                    </a-input>
                                </a-form-model-item>
                                <a-form-model-item prop="confirmPass">
                                    <a-input
                                        type="text"
                                        v-model="formLogin.confirmPass"
                                        placeholder="请确认密码(输入6-15位英文字母或数字)">
                                    </a-input>
                                </a-form-model-item>
                                <SlideVerify ref="slideblock" @success="onSuccess" @again="onAgain" @fail="onFail" @refresh="onRefresh" :slider-text="sliderText" :imgs="imgs" :accuracy="accuracy"></SlideVerify>
                                <a-form-model-item prop="smsCode" class="phonecode_box">
                                    <div style="display:flex;">
                                        <a-input type="text" v-model="formLogin.smsCode" placeholder="请输入验证码"></a-input>
                                        <a-button type="primary" class="code_button" :disabled="smscodeDisabled" @click.native="getPhoneCode">{{smstext}}</a-button>
                                    </div>
                                </a-form-model-item>
                                <a-button
                                    @click="resetPass"
                                    type="primary"
                                    class="resetPass-button"
                                    :disabled='passdisabled'>
                                    立即使用</a-button>
                                <div class='text-box'>
                                    <span class="txt-d">已有账号，</span>
                                    <span class="txt-b" @click="goLogin">去登录</span>
                                </div>
                            </div>
                        </a-form-model>
                    </div>
                </div>
            </div>
            <div class="container-right"></div>
            <div class="footer">
                <div class="text1">AI云卫士推荐您使用chrome、Firefox浏览器</div>
                <div class="text2">2016-2026 skyinfor Inc.All Rights Reserved天覆科技 沪ICP备16033065号-3</div>
            </div>
        </div>
    </div>
</template>

<script>
var md5 = require('@/libs/md5');
import {sendSms,login,smsLogin,resetPass} from "@/api/data.js";
import {checkMobile,checkPassword} from "@/libs/rules";
import cookies from "@/libs/util.cookies.js";
import SlideVerify from "./components/slide-verify";
export default {
    components:{SlideVerify},
    data(){
        return {
            activeIndex:1,
            formLogin: {
                account: '',
                password: '',

                mobile:'',
                phoneCode:'',

                phone:'',
                newPass:'',
                confirmPass:'',
                smsCode:'',
            },
            checked:false,
            // 表单校验
            rules: {
                account: [{required: true,message: '请输入用户',trigger: 'blur'}],
                password: [{required: true,message: '请输入密码',trigger: 'blur'},
                { validator: checkPassword, trigger: 'blur' }],

                mobile:[{required: true, message: '请输入手机号',trigger: 'blur'},
                { validator: checkMobile, trigger: 'blur' }],
                phoneCode:[{required: true,message: '请输入验证码',trigger: 'blur'}],

                phone:[{required: true, message: '请输入手机号',trigger: 'blur'},
                { validator: checkMobile, trigger: 'blur' }],
                newPass:[{required: true, message: '请输入密码',trigger: 'blur'},
                { validator: checkPassword, trigger: 'blur' }],
                confirmPass:[{required: true, message: '请输入确认密码',trigger: 'blur'},
                { validator: checkPassword, trigger: 'blur' }],
                smsCode:[{required: true,message: '请输入验证码',trigger: 'blur'}]
            },
            codeDisabled:true,
            text:'获取验证码',

            smscodeDisabled:true,
            smstext:'获取验证码',

            time:60,
            timer:'',
            geetestObj: {}, //二次验证的时候需要的三个geetest数据
            showContent:'login',
            passdisabled:false,
            disabled:false,
            accountInfo:'',

            sliderText: '按住左边滑块，拖动完成上方拼图',
            imgs: [require('./images/img.jpg'),require('./images/img1.jpg'),require('./images/img2.jpg'),
            require('./images/img3.jpg'),require('./images/img4.jpg'),require('./images/img5.jpg')],
            accuracy: 2, // 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
            isCheackS:false,
        }
    },
    watch:{
        activeIndex(){
            this.$nextTick(()=>{
                this.$refs.loginForm.clearValidate();
                this.resetForm();
                this.isCheackS = false;
            })
        },
        showContent(){
            this.$nextTick(()=>{
                this.$refs.loginForm.clearValidate();
                this.resetForm();
                this.isCheackS = false;
            })
        },
        'formLogin.account': function(){
            this.changeCheck();
        },
        'formLogin.password': function(){
            this.changeCheck();
        },
        'formLogin.mobile': function(newVal, oldVal){
            this.$nextTick(() => {
                this.$refs.loginForm.validateField('mobile', (mobileError) => {
                    if(mobileError){
                        this.codeDisabled = true;
                    }else{
                        this.codeDisabled = false;
                    }
                })
            })
        },
        'formLogin.phone': function(newVal, oldVal){
            this.$nextTick(() => {
                this.$refs.loginForm.validateField('phone', (phoneError) => {
                    if(phoneError){
                        this.smscodeDisabled = true;
                    }else{
                        this.smscodeDisabled = false;
                    }
                })
            })
        },
    },
    mounted(){
        this.getAccount();
    },
    created () {
        document.onkeydown = (e) => {
            if (window.event === undefined) {
                var key = e.keyCode
            } else {
                var key = window.event.keyCode
            }
            if (key === 13 && this.$route.name === 'login') {
                this.submit()
            }
        }
    },
    methods:{
        getAccount(){
            this.accountInfo = JSON.parse(localStorage.getItem('account-Info'));
            if(this.accountInfo){
                this.formLogin.account = this.accountInfo.account;
                this.formLogin.password = this.accountInfo.password;
                this.checked = this.accountInfo.checked;
            }
        },
        // 改变保持登录
        changeCheck(){
            // 若保持登录 将信息放在localstorage 若不保持 则删除
            if(this.checked){
                let obj = {
                    account:this.formLogin.account,
                    password:this.formLogin.password,
                    checked:this.checked,
                }
                localStorage.setItem("account-Info", JSON.stringify(obj));
            }else{
                localStorage.removeItem('account-Info')
            }
        },
        onSuccess(){
            this.isCheackS = true;
        },
        onFail(){
        },
        onRefresh(){
            this.isCheackS = false;
        },
        onAgain() {
            // 刷新
            this.isCheackS = false;
            this.handleClick();
        },
        handleClick() {
            this.$refs.slideblock.reset();
        },
        getTime(){
            this.timer = setInterval(() => {
                if(this.time == 0){
                    this.text = '获取验证码';
                    this.codeDisabled = false;
                }else{
                    this.time--;
                    this.text = this.time + 's';
                }
            }, 1000)
        },
        getPhoneCode(){
            if(!this.isCheackS){
                this.$message.warning('请先验证拖动滑块');
                return
            }
            if(this.showContent === 'login'){
                let moblieSign = 'aiyun_' + this.formLogin.mobile;
                let sign = md5(moblieSign);
                sendSms({mobile:this.formLogin.mobile,sign,type:'login'}).then(res => {
                    if(res.code === 1){
                        clearInterval(this.timer);
                        this.codeDisabled = true;
                        this.time = 60;
                        this.text = this.time + 's';
                        this.getTime();
                    }
                })
            }else{
                let moblieSign = 'aiyun_' + this.formLogin.phone;
                let sign = md5(moblieSign);
                sendSms({mobile:this.formLogin.phone,sign,type:'resetpassword'}).then(res => {
                    if(res.code === 1){
                        clearInterval(this.timer);
                        this.smscodeDisabled = true;
                        this.time = 60;
                        this.smstext = this.time + 's';
                        this.getTime();
                    }
                })
            };
        },
        resetPassword(){
            this.showContent = 'resetPass';
            this.resetForm();
        },
        goLogin(){
            this.showContent = 'login';
            this.activeIndex = 1;
            this.resetForm();
        },
        resetForm(){
            if(this.checked){
                this.formLogin.account = this.accountInfo.account;
                this.formLogin.password = this.accountInfo.password;
            }else{
                this.formLogin.account = '';
                this.formLogin.password = '';
            }
            this.formLogin.mobile = '';
            this.formLogin.phoneCode = '';
            this.formLogin.phone = '';
            this.formLogin.newPass = '';
            this.formLogin.confirmPass = '';
            this.formLogin.smsCode = '';
        },
        submit(){
            this.$refs.loginForm.validate((valid) => {
                if(valid){
                    this.disabled = true;
                    if(this.activeIndex === 1){
                        login({
                            account:this.formLogin.account,
                            password:this.formLogin.password,
                        }).then(res => {
                            this.disabled = false;
                            if(res.code === 1){
                                this.login(res.data);
                            }
                        }).catch(()=>{
                            this.disabled = false;
                        })
                    }else{
                        smsLogin({
                            mobile:this.formLogin.mobile,
                            sms_code:this.formLogin.phoneCode,
                        }).then(res => {
                            this.disabled = false;
                            if(res.code === 1){
                                this.login(res.data);
                            }
                        }).catch(()=>{
                            this.disabled = false;
                        })
                    }
                }
            })
        },
        login(data){
            if(data.userinfo.is_match){
                cookies.set('token',data.token);
                let teaminfo = {
                    loginStatu:data.login_status,
                    matching_id:data.userinfo.matching_id,
                    matching_userid:data.userinfo.matching_userid,
                    team_id:data.userinfo.team_id,
                }
                this.$store.commit('getUserInfo');
                this.$store.commit('setPlatformData',{platform_name:data.platform.platform_name,logo_url:data.userinfo.platform_logo_url});
                localStorage.setItem('teamInfo',JSON.stringify(teaminfo));
                this.$router.replace({path:'/'});
            }else{
                let urlData = `uid=${data.userinfo.uid}&token=${data.token}&platform_name=${data.platform.platform_name}&platform_id=${data.platform.platform_id}&loginStatu=${data.login_status}&type=${data.userinfo.type}`;
                if(process.env.NODE_ENV === 'production'){
                    window.open(`https://cloud.ai-safer.cn/#/home?${urlData}`, '_blank');
                }else{
                    window.open(`http://ai.skyinfordata.com:61008/#/home?${urlData}`, '_blank');
                }
            }
        },
        resetPass(){
            this.$refs.loginForm.validate((valid) => {
                if(valid){
                    if(this.formLogin.confirmPass != this.formLogin.newPass){
                        this.$message.warning('两次密码不想同，请修改');
                        this.formLogin.confirmPass = '';
                        return
                    }
                    this.passdisabled = true;
                    resetPass({
                        account:this.formLogin.phone,
                        password:this.formLogin.newPass,
                        sms_code:this.formLogin.smsCode,
                    }).then(res => {
                        if(res.code === 1){
                            this.passdisabled = false;
                            this.$message.success('重置密码成功');
                            this.goLogin();
                        }
                    }).catch(()=>{
                        this.passdisabled = false;
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.login{
    height: 100vh;
    width:100%;
    min-width: 1366px;
    background: linear-gradient(-82deg,#ffffff 0%, #e5f5ff 83%);
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: center;
    .container{
        width:100%;
        height:100%;
        background: #fff;
        display: flex;
        position:relative;
        font-family: PingFang SC;
        .container-left{
            width:562px;
            padding:9% 72px 0 96px;
            box-sizing: border-box;
            .text{
                text-align: left;
                font-size: 48px;
                line-height: 67px;
                color:#000;
            }
            .login_form{
                font-family: Source Han Sans CN;
                .tab{
                    display:flex;
                    justify-content: space-between;
                    margin:50px 0 33px;
                    .tabs{
                        width:160px;
                        text-align: center;
                        font-size: 18px;
                        line-height:42px;
                        height:42px;
                        color: #B7B7B7;
                        cursor: pointer;
                    }
                    .active_tabs{
                        border-bottom: 4px solid #01a1ff;
                        color:#01a1ff;
                    }
                }
                .tab2{
                    margin:39px 0 33px;
                    color:#01A1FF;
                    text-align: center;
                }
                .code_button{
                    width: 170px;
                    background: linear-gradient(93.92deg, #029FFF -25.7%, #026DFF 106.24%);
                    border:0;
                    border-radius: 50px;
                    margin-left:10px;
                    height:44px;
                }
                .code_button[disabled]{
                    background:#eee;
                    color:#B8B8B8;
                }
            }
        }
        .container-right{
            flex:1;
            background: url('./images/download.png') no-repeat;
            background-size: 100% 100%;
        }
        .footer{
            position: absolute;
            bottom:40px;
            left:50%;
            transform: translateX(-50%);
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            .text1{
                color:#fff;
            }
            .text2{
                color:#C4C4C4;
                margin-top:10px;
            }
        }
        ::v-deep .ant-input{
            height: 44px;
            line-height: 44px;
            border-radius: 50px;
            background: #F6F6F6;
            font-family: Source Han Sans CN;
        }
        ::v-deep .ant-form-item{
            margin-bottom:20px;
            .ant-form-item-with-help{
                margin-bottom: 0;
            }
        }
        ::v-deep .has-error{
            .ant-form-explain{
                position: absolute;
            }
        }
        .check_box{
            margin-bottom:30px;
            ::v-deep .ant-form-item-control{
                .ant-checkbox-wrapper-checked{
                    float: left;
                }
                .txt{
                    float: right;
                    color: #01a1ff;
                    cursor: pointer;
                }
            }
        }
        .loginbox{
            display:flex;
            flex-direction: column;
            justify-content: flex-start;
        }
        .resetPassbox{
            display:flex;
            flex-direction: column;
            justify-content: flex-start;
        }
        .button-login , .resetPass-button{
            width:190px;
            background: linear-gradient(91.36deg, #01A1FF 0.68%, #0167FF 103.62%);
            border:0;
            box-shadow: 0px 2px 10px rgba(1, 145, 255, 0.44);
            border-radius: 80px;
            height:48px;
        }
        .text-box{
            display:flex;
            font-size: 14px;
            line-height:18px;
            margin:40px 0 0;
            .txt-d{
                color: #4b4b4b;
            }
            .txt-b{
                color: #01a1ff;
                margin-left:9px;
                cursor: pointer;
            }
        }
    }
}
</style>